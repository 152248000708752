<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Default Earning Deductions</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'earning-deductions'}">Earning Deductions</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Default Earning Deductions</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <br>
            <div>
                <h4 class="my-3">Default Earnings</h4>
                <b-card>
                    <b-row>
                        <b-col lg="6" class="mb-1rem">
                            <router-link
                                v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                :to="{  name: 'create-default-earning-deductions', 
                                        params: { type:'earning'}}"
                                class="btn btn-primary text-right"
                                tag="button"
                            >
                                <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Default Earning
                            </router-link>
                        </b-col>
                    </b-row>

                    <b-table
                        show-empty
                        striped
                        hover
                        :items="type_two"
                        :fields="fields"
                        responsive
                    >
                        <template v-slot:cell(payroll_formula.conditions)="row">
                            <div class="row">
                                <div class="col-md-12">
                                    <span v-if="JSON.parse(row.item.payroll_formula.conditions).length>1">Default Formula : </span>{{ JSON.parse(row.item.payroll_formula.conditions)[JSON.parse(row.item.payroll_formula.conditions).length-1].formula }}
                                </div>
                            </div>
                            <div class="row mt-3" v-for="(condition, index) in JSON.parse(row.item.payroll_formula.conditions).slice(0, JSON.parse(row.item.payroll_formula.conditions).length-1)" :key="index">
                                <div class="col-md-12" v-for="(value,name,index2) in condition" :key="index2">
                                    <span>{{ str_title(name) }} {{ index+1 }}: {{ value }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-slot:cell(values)="row">
                           <span v-if="row.item.values.length > 2"> {{ row.item.values }} </span> 
                        </template>
                        <template v-slot:cell(actions)="row">
                            <span class="actions">
                                <span class="ml-1 circle">
                                    <router-link
                                        class=""
                                        v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                        v-b-tooltip.hover title="Edit"
                                        :to="{  name: 'edit-default-earning-deductions', 
                                                params: { id:  row.item.id,type:row.item.type}}"
                                    >
                                        <font-awesome-icon icon="pen"></font-awesome-icon>
                                    </router-link>
                                </span>
                                <span class="ml-1 text-danger circle" @click="statusInfoModal(row.item, row.index, $event.target)" v-b-tooltip.hover title="Deactivate" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS') && row.item.status">
                                    <font-awesome-icon
                                        icon="ban"
                                        v-b-modal.modal-center
                                    ></font-awesome-icon>
                                </span>
                                <span class="ml-1 circle" @click="historyView(row.item)"  v-b-tooltip.hover title="History">
                                    <font-awesome-icon
                                        icon="history"
                                        style="cursor: pointer;"
                                    ></font-awesome-icon>
                                </span>
                            </span>
                        </template>
                        <template v-slot:cell(status)="row">
                            <h6 v-if="row.item.status"><b-badge variant="success">ACTIVE</b-badge></h6>
                            <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                        </template>
                    </b-table>
                </b-card>
            </div>
            <br> <hr> <br>
            <div>
                <h4 class="my-3">Default Deductions</h4>
                <b-card>
                    <b-row>
                        <b-col lg="6" class="mb-1rem">
                            <router-link
                                v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                :to="{  name: 'create-default-earning-deductions', 
                                        params: { type:'deduction'}}"
                                class="btn btn-primary text-right"
                                tag="button"
                            >
                                <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Default Deduction
                            </router-link>
                        </b-col>
                    </b-row>

                    <b-table
                        show-empty
                        striped
                        hover
                        :items="type_one"
                        :fields="fields"
                        responsive
                    >     
                        <template v-slot:cell(payroll_formula.conditions)="row">
                            <div class="row">
                                <div class="col-md-12">
                                    <span v-if="JSON.parse(row.item.payroll_formula.conditions).length>1">Default Formula : </span>{{ JSON.parse(row.item.payroll_formula.conditions)[JSON.parse(row.item.payroll_formula.conditions).length-1].formula }}
                                </div>
                            </div>
                            <div class="row mt-3" v-for="(condition, index) in JSON.parse(row.item.payroll_formula.conditions).slice(0, JSON.parse(row.item.payroll_formula.conditions).length-1)" :key="index">
                                <div class="col-md-12" v-for="(value,name,index2) in condition" :key="index2">
                                    <span>{{ str_title(name) }} {{ index+1 }}: {{ value }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-slot:cell(values)="row">
                           <span v-if="row.item.values.length > 2"> {{ row.item.values }} </span> 
                        </template>
                        <template v-slot:cell(actions)="row">
                            <span class="actions">
                                <span>
                                    <router-link
                                        class="circle"
                                        v-b-tooltip.hover title="Edit"
                                        :to="{  name: 'edit-default-earning-deductions', 
                                                params: { id:  row.item.id,type:row.item.type}}"
                                    >
                                        <font-awesome-icon
                                            v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                            icon="pen"
                                        ></font-awesome-icon>
                                    </router-link>
                                </span>
                                <span class="ml-1 text-danger circle" @click="statusInfoModal(row.item, row.index, $event.target)" v-b-tooltip.hover title="Deactivate" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS') && row.item.status">
                                    <font-awesome-icon
                                        icon="ban"
                                        v-b-modal.modal-center
                                    ></font-awesome-icon>
                                </span>
                                <span class="ml-1 circle" @click="historyView(row.item)"  v-b-tooltip.hover title="History">
                                    <font-awesome-icon
                                        icon="history"
                                        style="cursor: pointer;"
                                    ></font-awesome-icon>
                                </span>
                            </span>
                            
                        </template>
                        <template v-slot:cell(status)="row">
                            <h6 v-if="row.item.status"><b-badge variant="success">ACTIVE</b-badge></h6>
                            <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                        </template>
                    </b-table>
                </b-card>
            </div>
            <br> <br>
            <!-- Info modal -->
            <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                centered
                size="sm"
                @hide="resetInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancel()">Cancel</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="deleteEarningDeduction(infoModal.content)"
                        style="margin-right:10px;"
                    >Delete</b-button>
                </div>
            </b-modal>

            <!-- Status modal -->
            <b-modal
                :id="statusModal.id"
                :title="statusModal.title"
                centered
                size="md"
                @hide="resetStatusInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancelStatusModal()">No</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="updateStatusEarningDeduction(statusModal.content)"
                        style="margin-right:10px;"
                    >Yes</b-button>
                </div>
            </b-modal>

                <!-- History modal -->
            <b-modal :id="history.id" :title="history.title" centered  size="xl" hide-footer>
                <b-table
                    show-empty
                    striped
                    hover
                    :items="history.rows"
                    :fields="history.fields"
                    no-local-sorting
                    responsive
                >
                    <template v-slot:cell(status)="row">
                        <h6 v-if="row.item.status == '1'"><b-badge variant="success">ACTIVE</b-badge></h6>
                        <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                    </template>
                </b-table>
            </b-modal>

        </b-container>
    </div>
</template>

<script>
import NotFound from "../../../errors/NotFound";
import default_error from "../../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
                order: "",
                type:""
            },
            api_error: "",
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: false},
                { key: "payroll_formula.conditions", label:"Formula", sortable: false , tdClass: 'tableWidth'},
                { key: "values", sortable: false},
                { key: "status", sortable: false},
                { key: "actions", label: "Actions", thStyle: { width: '10%' } }
            ],
            type_one: null,
            type_two:null,
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            statusModal: {
                id: "status-modal",
                title: "",
                content: ""
            },
            message:"",
            history: {
                id: 'history-modal',
                title: 'History',
                fields: [
                    { key: "name", sortable:false },
                    { key: "formula", sortable:false},
                    { key: "values", sortable:false},
                    { key: "status", sortable:false},
                    { key: "created_by_name", label:"Updated By", sortable:false},
                    { key: "updated_at", label:"Updated At", sortable:false},
                ],
                rows: []
            }

        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_EARNING_DEDUCTIONS");
        this.getDEDTypeOne();
        this.getDEDTypeTwo();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        statusInfoModal(item, index, button) {
            this.statusModal.title = `Status Confirmation`;
            this.message = 'Are you sure you want to Deactivate <b>'+this.str_title(item.name)+'</b> Default '+this.str_title(item.type)+ '?';
            this.statusModal.content = item;
            this.$root.$emit("bv::show::modal", this.statusModal.id, button);
        },

        resetStatusInfoModal() {
            this.statusModal.title = "";
            this.statusModal.content = "";
        },

        cancelStatusModal: function() {
            this.$root.$emit("bv::hide::modal", this.statusModal.id);
        },

        updateStatusEarningDeduction: function(defaultEarningDeductions) {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {};
            let data = {
                id: defaultEarningDeductions.id,
                status:0
            }
            this.axios
                .post(
                    this.$api.update_status_default_earning_deduction,
                    data,
                    query
                )
                .then(response => {
                    if (response.data.code == 200) {
                        this.$root.$emit("bv::hide::modal", this.statusModal.id);
                        this.success = response.data.message;
                        this.getDEDTypeOne();
                        this.getDEDTypeTwo();
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },

        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Delete this?';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },

        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },

        deleteEarningDeduction: function(defaultEarningDeductions) {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {};
            this.axios
                .post(
                    this.$api.delete_default_earning_deduction,
                    JSON.parse(defaultEarningDeductions),
                    query
                )
                .then(response => {
                    if (response.data.code == 200) {
                        this.$root.$emit("bv::hide::modal", this.infoModal.id);
                        this.success = response.data.message;
                        this.getDEDTypeOne();
                        this.getDEDTypeTwo();
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },

        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },


        getDEDTypeOne: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            this.urlBody.type= "deduction";
            query['params']=this.deleteEmptyKeys(this.urlBody);
            this.axios.get(this.$api.get_default_earning_deductions, query)
                .then(response => {
                    this.type_one = response.data.data;
                    this.api_error = "";
                    this.updateProgressBar(true);
                }).catch(err => {
                    console.log(err);
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.type_one = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        getDEDTypeTwo: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            this.urlBody.type= "earning";
            query['params']=this.deleteEmptyKeys(this.urlBody);
            this.axios.get(this.$api.get_default_earning_deductions, query)
                .then(response => {
                    this.type_two = response.data.data;
                    this.api_error = "";
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.type_one = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        historyView(item) {
            this.history.rows = JSON.parse(item.history);
            this.$root.$emit("bv::show::modal", this.history.id);
        }
    }
};
</script>
<style lang="scss">
@import "../../../../assets/css/custom.scss";
.tableWidth {
    max-width: 350px;
}
</style>
